<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader> 新規予約 </CCardHeader>
        <CCardBody>
          <UploadExcelComponent
              :on-success="handleSuccess"
              :header-array="fields"
              :before-upload="beforeUpload"
              :example-file="exampleFile"
              :import-filename="importFilename"
              :get-string="getString"
              style="margin-bottom: 30px" />
          <CRow class="mb-2">
            <CCol sm="8" style="display: flex;">
              <CCol sm="4">
                <CInput id="date" :label="'予約日時'" type="date" horizontal @change="selectDate"></CInput>
              </CCol>
              <CCol sm="4" style="display: flex;">
                <CSelect id="hour" :options="hourOptions" style="width:100px" horizontal @change="selectHour"></CSelect>
                <CSelect id="minute" :options="minuteOptions" style="width:100px" horizontal @change="selectMinute"></CSelect>
              </CCol>
              <CCol sm="4">
                <CButton :disabled="Disabled" class="btn btn-info mr-2" icon="el-icon-upload" @click="registerSchedule">予約</CButton>
              </CCol>
            </CCol>
          </CRow>
          <div v-if="loading">
            <LoadingCompoment />
          </div>
          <div v-else :key="key">
            <CDataTable
              hover
              striped
              :items="tableData"
              :fields="fields"
              :items-per-page-select="{
                  label: '表示件数',
                  values: [10, 20, 50, 100, 200, 500],
              }"
              :items-per-page="200"
              pagination
            >
              <td slot="id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].id}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].id ? errors[index].id : ''"
                    variant='ghost'
                >
                    {{item.id}}
                </CButton>
              </td>
              <td slot="item_id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_id}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].item_id ? errors[index].item_id : ''"
                    variant='ghost'
                >
                  {{item.item_id}}
                </CButton>
              </td>
              <td slot="arrived_at" v-bind:class="{'bg-danger': !!errors[index] && errors[index].arrived_at}"
                  slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].arrived_at ? errors[index].arrived_at : ''"
                    variant='ghost'
                >
                  {{ item.arrived_at }}
                </CButton>
              </td>
              <td slot="shop" v-bind:class="{'bg-danger': !!errors[index] && errors[index].shop}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].shop ? errors[index].shop : ''"
                    variant='ghost'
                >
                  {{item.shop || ''}}
                </CButton>
              </td>
              <td slot="item_master_id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_master_id}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].item_master_id ? errors[index].item_master_id : ''"
                    variant='ghost'
                >
                  {{item.item_master_id || ''}}
                </CButton>
              </td>
              <td slot="shelf_id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].shelf_id}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].shelf_id ? errors[index].shelf_id : ''"
                    variant='ghost'
                >
                  {{item.shelf_id || ''}}
                </CButton>
              </td>
              <td slot="item_masters_size" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_masters_size}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].item_masters_size ? errors[index].item_masters_size : ''"
                    variant='ghost'
                >
                    {{item.item_masters_size || ''}}
                </CButton>
              </td>
              <td slot="item_masters_color" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_masters_color}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].item_masters_color ? errors[index].item_masters_color : ''"
                    variant='ghost'
                >
                    {{item.item_masters_color || ''}}
                </CButton>
              </td>
              <td slot="item_code" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_code}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].item_code ? errors[index].item_code : ''"
                    variant='ghost'
                >
                    {{item.item_code || ''}}
                </CButton>
              </td>
              <td slot="item_masters_name" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_masters_name}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].item_masters_name ? errors[index].item_masters_name : ''"
                    variant='ghost'
                >
                    {{item.item_masters_name || ''}}
                </CButton>
              </td>
              <td slot="item_masters_cost" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_masters_cost}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].item_masters_cost ? errors[index].item_masters_cost : ''"
                    variant='ghost'
                >
                    {{item.item_masters_cost_commas || ''}}
                </CButton>
              </td>
              <td slot="item_price" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_price}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].item_price ? errors[index].item_price : ''"
                    variant='ghost'
                >
                    {{item.item_price_commas || ''}}
                </CButton>
              </td>
              <td slot="item_masters_retail_price" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_masters_retail_price}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].item_masters_retail_price ? errors[index].item_masters_retail_price : ''"
                    variant='ghost'
                >
                    {{item.item_masters_retail_price_commas || ''}}
                </CButton>
              </td>
              <td slot="pp_bs_code" v-bind:class="{'bg-danger': !!errors[index] && errors[index].pp_bs_code}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].pp_bs_code ? errors[index].pp_bs_code : ''"
                    variant='ghost'
                >
                    {{item.pp_bs_code || ''}}
                </CButton>
              </td>
              <td slot="is_special" v-bind:class="{'bg-danger': !!errors[index] && errors[index].is_special}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].is_special ? errors[index].is_special : ''"
                    variant='ghost'
                >
                    {{item.is_special || ''}}
                </CButton>
              </td>
              <td slot="status" v-bind:class="{'bg-danger': !!errors[index] && errors[index].status}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].status ? errors[index].status : ''"
                    variant='ghost'
                >
                    {{item.status || ''}}
                </CButton>
              </td>
            </CDataTable>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>  
</template>
<script>
import Vue from "vue";
import UploadExcelComponent from '@/components/UploadExcel/index.vue';
import ResourceApi from "@/api/resourceApi";
import apiStockOther from '../../api/stockApi';
import apiImportScheduleOther from '../../api/importScheduleApi';
import {numberWithCommas, setOptionWholeHour, setOptionByThirty} from "@/utils/utils";

const apiImportSchedules = new ResourceApi('import-schedules');

export default {
  name: 'CreateSchedule',
  components: { UploadExcelComponent },
  data () {
    return {
      importSchedule: {
        csv_filename: '',
        scheduled_at: '',
        status: 0,
      },
      loading: false,
      tableData: [],
      errors: {},
      tableHeader: [],
      headerArray: [],
      exampleFile: {
        path: '/csv/stocks-3_24_2021.csv',
        name: 'stocks.csv',
      },
      key: Math.random(),
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'item_id', label: '商品ID' },
        { key: 'arrived_at', label: '*入荷日' },
        { key: 'shop', label: '店舗' },
        { key: 'item_master_id', label: '*商品マスタID' },
        { key: 'shelf_id', label: '*棚ID' },
        { key: 'item_code', label: '品番コード'},
        { key: 'item_masters_name', label: '品名' },
        { key: 'item_masters_size', label: '製品サイズ名' },
        { key: 'item_masters_color', label: 'カラー名' },
        { key: 'item_masters_cost', label: '仕入原価' },
        { key: 'item_price', label: '*販売価格' },
        { key: 'item_masters_retail_price', label: 'プロパー上代' },
        { key: 'pp_bs_code', label: '*PP/BS' },
        { key: 'is_special', label: '*優待' },
        { key: 'status', label: '状態' },
      ],
      Disabled: true,
      selectedDate: '',
      selectedTime: '',
      selectedHour: '',
      selectedMinute: '',
      csvString: '',
      hourOptions: [],
      minuteOptions: [],
    }
  },
  created() {
    this.hourOptions = setOptionWholeHour();
    this.minuteOptions = setOptionByThirty();
  },
  methods: {
    async handleSuccess({results, header}) {
      if (header.error_header) {
          let mess = 'ヘッダー名が正しくありません';
          Vue.$toast.error(mess)
          return;
      }

      let itemMasterIds = [];
      let data = [];
      results.map((r) => {
        if(!itemMasterIds.includes(r.item_master_id)){
          itemMasterIds.push(r.item_master_id);
        }
        if(r.id || r.item_id ||
            r.item_master_id || r.shelf_id || r.shelf_id || r.arrived_at ||
            r.item_code || r.item_masters_name || r.item_masters_size ||
            r.item_masters_color || r.item_masters_cost || r.item_price ||r.item_masters_retail_price){
          data.push(r)
        }
      })
      this.getAllItemMasters(itemMasterIds, data)
      this.tableHeader = header
      this.key = Math.random()
    },
    overrideItemMaster(itemMasters, results){
      results.map((r, index) => {
            itemMasters.map(i => {
                if(i.id == r.item_master_id){
                    results[index].item_code = i.item_code;
                    results[index].item_masters_name = i.name;
                    results[index].item_masters_size = i.size.name;
                    results[index].item_masters_color = i.color.color_code + ':' + i.color.name;
                    results[index].item_masters_cost = i.cost;
                    results[index].item_price = r.item_price ? r.item_price  : i.price;
                    results[index].item_masters_retail_price = i.retail_price;
                }
            })
          results[index].item_masters_cost_commas = numberWithCommas(r.item_masters_cost);
          results[index].item_price_commas = numberWithCommas(r.item_price);
          results[index].item_masters_retail_price_commas = numberWithCommas(r.item_masters_retail_price);
        })
        return results;
    },
    beforeUpload(file) {
      this.errors = {}
      return true
    },
    getAllItemMasters(data, results) {
      this.loading = true
      let listStr = data.join()
      apiStockOther.getItemMasters({list_id_str: listStr}).then(response => {
        let itemMasters = response.data;
        this.tableData = this.overrideItemMaster(itemMasters, results)
        apiStockOther.uploadCsv(this.tableData).then(response => {
          if (response.data.errors.length === 0) {
                this.Disabled = false
            } else {
              const errors = {}
              response.data.errors.map((e) => {
                errors[e.line] = {}
                Object.keys(e.errors).map((error) => {
                  errors[e.line][error] = e.errors[error][0]
                })
              })
              this.errors = errors
              this.key = Math.random()
              this.Disabled = true
            }
        })
      }).catch(error => {
      }).finally(() =>{
          this.loading = false
      })
    },
    registerSchedule() {
      this.loading = true;

      if(!this.selectedDate) {
        Vue.$toast.error('日付を入力してください')
        this.loading = false
        return;
      }
      if(!this.selectedHour) {
        Vue.$toast.error('時間を入力してください')
        this.loading = false
        return;
      }
      if(!this.selectedMinute) {
        Vue.$toast.error('分を入力してください')
        this.loading = false
        return;
      }

      this.selectedTime = this.selectedHour + ":" + this.selectedMinute
      this.importSchedule.scheduled_at = this.selectedDate + " " + this.selectedTime
      let params = {
        importSchedule: this.importSchedule,
        tableData: this.tableData,
        unicodeString: this.csvString,
      }
      apiImportSchedules.create(params).then(response => {
          Vue.$toast.success('登録成功しました。')
          this.goList()
      }).catch(error => {
          if(error.response){
            Vue.$toast.error('不適切なデータがあります。')
          }
      }).finally(() =>{
          this.loading = false
      })
    },
    selectDate(val) {
      this.selectedDate = val
    },
    selectHour(e) {
      const val = e.target.value
      this.selectedHour = val
    },
    selectMinute(e) {
      const val = e.target.value
      this.selectedMinute = val
    },
    importFilename(fileName) {
      this.importSchedule.csv_filename = fileName
    },
    getString(unicodeString) {
      this.csvString = unicodeString
    },
    goList() {
      this.$router.back()
    },

  }
}
</script>
<style>
 .btn-error{
     min-width: 40px;
     min-height: 40px;
 }
</style>
