import request from './request'

const importScheduleApi = {
    downloadCsv(data) {
        return request({
            url: 'import-schedules/download-csv',
            method: 'get',
            params: data
        })
    },
}
export default importScheduleApi
